import React from 'react';
import Helmet from 'react-helmet';

const containerStyle = {
    height: '100vh',
    margin: 0,
};

const Philippe = () => (
    <div style={containerStyle}>
        <Helmet>
            <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/ajP7vWZRS04?rel=0&autoplay=1"
            frameBorder="0"
            allowFullScreen
        />
    </div>
);

export default Philippe;
